<template>
  <div>
    <vx-card class="mb-base directors-card">
      <div class="my-5">
        <vs-table
          max-items="15"
          pagination
          :data="childrenData"
          :noDataText="noDataText"
          @sort="handleSort"
        >
          <div slot="header" class="w-full">
            <div class="vx-breadcrumb md:block hidden">
              <ul class="flex flex-wrap items-center">
                <li class="inline-flex items-center">
                  <router-link :to="{ name: 'director-learning-centre-list' }"
                    >Manage</router-link
                  >
                  <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevrons-right"
                      >
                        <polyline points="13 17 18 12 13 7" />
                      </svg>
                    </span>
                  </span>
                </li>

                <li class="inline-flex items-center">
                  <a href="javascript:void(0)" class>Children</a>
                </li>
              </ul>
            </div>
            <div class="flex flex-wrap items-center my-8">
              <vs-row
                vs-align="flex-start"
                vs-type="flex"
                vs-justify="left"
                class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5"
              >
                <vs-col>
                  <div class="select-wrapper mt-0">
                    <div class="vs-component is-label-placeholder">
                      <v-select
                        :options="sortOptions"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="sortByFilter"
                      >
                        <template #header>
                          <label for class="label">Sort By</label>
                        </template>
                        <template #open-indicator="{ attributes }">
                          <span v-bind="attributes">
                            <vs-icon icon="arrow_drop_down"></vs-icon>
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
              <vs-row
                vs-align="flex-start"
                vs-type="flex"
                vs-justify="left"
                class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5"
              >
                <vs-col>
                  <!-- <label class="hidden text-black text-opacity-0 md:block mb-2">Search</label> -->
                  <vs-input
                    class="w-full search-input"
                    icon-pack="feather"
                    icon="icon-search"
                    v-model="searchQuery"
                    placeholder="Search..."
                  />
                </vs-col>
              </vs-row>
              <div class="w-full md:w-1/3 md:text-right">
                <vs-col vs-type="flex" vs-align="left">
                  <div class="centerx mx-1">
                    <vs-button
                      color="primary"
                      @click="csvUpload.config.popupToUploadCsv = true"
                      type="filled"
                    >
                      Mass Upload
                    </vs-button>
                    <vs-popup
                      title="Mass Upload"
                      :active.sync="csvUpload.config.popupToUploadCsv"
                    >
                      <div class="centerx">
                        <p>
                          This Mass Upload enables you to upload several
                          Children at once.
                        </p>
                        <p class="mt-2">
                          The “Upload Template” option will provide a file in
                          which to populate the Child & Parent / Guardian
                          details which can be uploaded at the same time.
                          Remember to save as a CSV. File.
                        </p>
                        <p class="mt-2">
                          When the file is ready use the Mass Upload option
                          below to select the file from your saved location
                          which will be followed by an “Import” option.
                        </p>
                        <div>
                          <vs-input
                            hidden
                            v-model="csvUpload.data.learningCenterId"
                            class="mt-5 w-full"
                            name="CenterId"
                          />
                        </div>

                        <div>
                          <vs-input
                            hidden
                            v-model="csvUpload.data.directorId"
                            class="mt-5 w-full"
                            name="directorId"
                          />
                        </div>

                        <div>
                          <div class="upload-img">
                            <input
                              type="file"
                              class="hidden"
                              ref="uploadImgInput"
                              @change="updateCurrImg"
                              accept="text/csv"
                            />
                            <vs-button
                              type="border"
                              class="btn-blue-border"
                              @click="$refs.uploadImgInput.click()"
                            >
                              Mass Upload
                            </vs-button>
                          </div>
                        </div>

                        <div v-if="showMassUploadButton" class="mt-3">
                          <vs-button @click="submitCsv">Import</vs-button>
                        </div>
                      </div>
                    </vs-popup>
                  </div>
                  <div class="mx-1">
                    <vs-button
                      color="primary"
                      :href="templateLink"
                      type="filled"
                    >
                      Upload Template
                    </vs-button>
                  </div>
                  <vs-button
                    class="mb-md-0 mb-2 mx-1"
                    align="right"
                    @click="addNewChild"
                    >Add Child</vs-button
                  >
                </vs-col>
              </div>
            </div>
          </div>
          <template slot="thead">
            <vs-th>Child's Name</vs-th>
            <vs-th>Child ID</vs-th>
            <vs-th>Room</vs-th>
            <vs-th>Age Group</vs-th>
            <vs-th>Primary Parents/Guardian</vs-th>
            <vs-th>Account Status</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.fullName">{{ tr.fullName | capitalize }}</vs-td>
              <vs-td :data="tr.childId"
                >{{ "undefined" != tr.childId ? tr.childId : "" }}
              </vs-td>
              <vs-td>{{ tr.room.length > 0 ? tr.room[0].name : "" }}</vs-td>
              <vs-td
                :data="tr.ageGroup.length > 0 ? tr.ageGroup[0].ageGroup : null"
                >{{
                  tr.ageGroup.length > 0
                    ? `${tr.ageGroup[0].ageGroup} Years`
                    : "-"
                }}
              </vs-td>

              <vs-td :data="tr.gender"
                >{{ "undefined" != tr.mainParent ? tr.mainParent : "" }}
              </vs-td>
              <vs-td :data="tr.accountStatus">
                <vs-button
                  class="mr-4 mt-1"
                  :color="tr.accountStatus == 'Active' ? 'success' : 'danger'"
                  >{{ tr.accountStatus }}
                </vs-button>
              </vs-td>
              <vs-td>
                <router-link
                  :to="{
                    name: 'center-admin-children-view',
                    params: { id: tr._id }
                  }"
                  class="nav-link d-flex align-items-center active"
                >
                  <feather-icon
                    icon="EyeIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                  />
                </router-link>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      showMassUploadButton: false,
      image: "",
      csvUpload: {
        config: {
          uploadCsvUrl:
            "http://localhost:3000/api/v1/centerAdmin/children/import",
          popupToUploadCsv: false,
          headers: {
            /*"Content-Type": "application/json",
              "Access-Control-Allow-Headers": "Content-Type",*/
          }
        },
        data: {
          learningCenterId: null,
          directorId: null
        }
      },
      templateLink: process.env.VUE_APP_DOMAIN + "/template/children.csv",
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "",
        dir: "asc",
        limit: 5000,
        page: 1
      },
      childrenData: [],
      serverResponded: false,
      searchQuery: "",
      awaitingSearch: false,
      addNewDataSidebar: false,
      sidebarData: {},
      // childrenData: {},
      sortByFilter: { label: "Sort By", value: "" },
      sortOptions: [
        { label: "Sort By", value: "" },
        { label: "Room", value: "room.name" },
        { label: "Age Group", value: "ageGroupId" }
      ],
      centerName: "",
      noDataText: "Currently no record available"
    };
  },
  methods: {
    ...mapActions("centerAdmin", [
      "getCenterAdminChildrenList",
      "importChildrenCsv"
    ]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          // this.logo = e.target.result
        };
        reader.readAsDataURL(input.target.files[0]);
      }
      this.showMassUploadButton = true;
    },

    async submitCsv() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append("child", this.image);
          data.append("learningCenterId", this.centerAdmin.learningCenterId);
          data.append("directorId", this.centerAdmin.learningCenter.directorId);

          this.customError = "";
          this.$vs.loading();
          this.importChildrenCsv(data)
            .then(res => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Children Csv Import",
                text: `Children file has been imported successfully. ${JSON.stringify(
                  res.data.data
                )}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
              this.$router.go(this.$router.currentRoute)
            })
            .catch(err => {
              this.$vs.notify({
                title: "Failure",
                text: `Could not import csv file. ${JSON.stringify(
                  err.response.data.message
                )}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
              this.$vs.loading.close();
              this.image = "";
              this.csvUpload.config.popupToUploadCsv = false;
              this.showMassUploadButton = false;
            })
            .finally(() => {
              this.$vs.loading.close();
              this.image = "";
              this.csvUpload.config.popupToUploadCsv = false;
              this.showMassUploadButton = false;
              this.$router.go(this.$router.currentRoute)
            });
        }
      });
    },
    async getChildrenData() {
      let self = this;
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.directorId = this.centerAdmin.learningCenterId;
      this.$vs.loading();

      await this.getCenterAdminChildrenList(this.dataTableParams).then(
        async res => {
          self.childrenData = await res.data.data.docs;

          await _.forEach(self.childrenData, (o, k) => {
            let primaryParent = _.find(o.parents, p => {
              return p.isPrimary == true;
            });

            _.forEach(o.parentData, (p, ki) => {
              if (
                "undefined" != typeof primaryParent &&
                p._id == primaryParent.parentId
              ) {
                if (!p.deleted) {
                  self.childrenData[k].mainParent = p.fullName;
                } else {
                  self.childrenData[k].mainParent = "";
                }
              }
            });
          });

          if (self.childrenData.length == 0 && "" != this.searchQuery) {
            self.noDataText =
              "Cannot find children with search text: '" +
              this.searchQuery +
              "'";
          }

          this.$vs.loading.close();
        }
      );
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getChildrenData();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getChildrenData();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getChildrenData();
    },
    viewHandler(id) {
      this.$router.push({
        name: "center-admin-children-view",
        params: { id: id }
      });
    },
    addNewChild(id) {
      this.$router.push({ name: "center-admin-children-add" });
    },
    editDetailHandler(data, id) {
      this.sidebarData = data;
      this.dataId = id;
    }
  },
  mounted() {
    // console.log(this.director);
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    centerAdmin() {
      return this.$store.state.AppActiveUser;
    }
  },
  watch: {
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getChildrenData();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    sortByFilter(obj) {
      let self = this;
      this.$vs.loading();
      this.dataTableParams.sort = obj.value;
      this.getChildrenData();
    }
  },
  created() {
    this.csvUpload.data.learningCenterId = this.centerAdmin.learningCenterId;
    this.csvUpload.data.directorId = this.centerAdmin.learningCenter.directorId;
    let self = this;
    this.$vs.loading();
    setTimeout(() => {
      self.$vs.loading();
      self.getChildrenData();
    }, 1000); // 1 sec delay
  }
};
</script>

<style>
.vs-input--input.hasIcon:focus + .vs-input--placeholder {
  display: none !important;
}
</style>
